<template>
  <div class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="24">
              <el-page-header
                  class="back"
                  title="返回"
                  @back="goBack"
                  content="关于"
              ></el-page-header>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <div class="sign-frame">
          <h1 style="font-size: 28px; font-weight: bold">{{ this.$common.WEB_NAME }}</h1>
          <h3>关于系统</h3>
          <h4 style="font-size: 14px; color: #03aef9">{{ version }}</h4>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "AboutSystemView",
  data: function () {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.sign-frame {
  width: 600px;
  height: 330px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -225px;
  margin-top: -240px;
  /*margin-right: 200px;*/
}

.sign-form {
  margin-top: 50px;
}
</style>
